<template>
	<div>
		<el-tabs type="border-card" @tab-click="getApproveList(tabpaneindex)" v-model="tabpaneindex">
			<el-tab-pane :label="item" :name="index+''" v-for="(item,index) in tabpanename">
				<el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%">
					<el-table-column type="index" label="序号" width="50">
					</el-table-column>
					<el-table-column prop="planName" show-overflow-tooltip label="方案名称">
					</el-table-column>
					<el-table-column prop="createPlanUser" show-overflow-tooltip label="提交人">
					</el-table-column>
					<el-table-column show-overflow-tooltip label="备注">
						<template slot-scope="scope">
							{{scope.row.cfiled1 ? scope.row.cfiled1:'无'}}
						</template>
					</el-table-column>
					<el-table-column show-overflow-tooltip label="提交时间">
						<template slot-scope="scope">
							{{moment(scope.row.checkCreateTime).format('YYYY-MM-DD HH:mm:SS')}}
						</template>
					</el-table-column>
					<el-table-column width="70" label="操作" v-if="tabpaneindex =='0'">
						<template slot-scope="scope">
							<el-button size="mini"
								@click="checktext = '',radio = '1',checkshow = true,approveApplydata = scope.row"
								type="primary" icon="el-icon-s-check">
							</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
		</el-tabs>
		<el-dialog :close-on-click-modal="false" width="50%" top="6vh" :visible.sync="checkshow">
			<div class="checkbox">
				<div>
					<el-radio v-model="radio" label="1">{{roleId == 1 ? '通过审核' : '通过申请并上报'}}</el-radio>
					<el-radio v-model="radio" label="2">驳回申请</el-radio>
				</div>
				<div v-if="radio == '2'">
					<el-input v-model="checktext"></el-input>
				</div>
				<div class="buttons">
					<el-button @click="checkshow = false">取 消</el-button>
					<el-button type="primary" @click="submitForm">确 定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Moment from 'moment'
	export default {
		data() {
			return {
				moment: Moment,
				userId: sessionStorage.getItem('userId'),
				roleId: sessionStorage.getItem('roleId'),
				radio: '1',
				checktext: '',
				tabpanename: ['待审核', '已通过', '已拒绝'],
				tabpaneindex: '0',
				tableData: [{}],
				checkshow: false,
				approveApplydata: ''
			}
		},
		created() {},
		mounted() {
			this.getApproveList(0)
		},
		methods: {
			getApproveList(type) {
				this.$post('programme/getApproveList', {
					userId: this.userId,
					// stateId: parseFloat(type) == 0 ? 5 : parseFloat(type)
					stateId: parseFloat(type)
				}).then((res) => {
					console.log(res)
					this.tableData = res
				})
				this.$nextTick(() => {
					this.$set(this.tableData)
				})
			},
			findtableData() {},
			submitForm() {
				this.$confirm(this.radio == '1' ? '此操作将通过该审核, 是否继续?' : '此操作将驳回该审核, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					beforeClose(action, instance, done) {
						if (action == "confirm") {
							instance.$refs.confirm.$el.onclick = function(e) {
								e = e || window.event
								console.log(e.detail)
								if (e.detail != 0) {
									done()
								}
							}
						} else {
							done()
						}
					}
				}).then(() => {
					let g = {
						id: this.approveApplydata.id,
						pass: this.radio
					}
					if (this.radio == '2' && !this.checktext) {
						this.$message({
							message: '驳回该审核请填写理由',
							type: 'warning'
						});
						return false
					} else if (this.radio == '2' && this.checktext) {
						g.explain = this.checktext
					}
					if (this.roleId == 1) {
						this.$post('programme/approve', g).then(() => {
							this.$message({
								message: '操作成功',
								type: 'success'
							});
							this.getApproveList(this.tabpaneindex)
							this.checkshow = false
						})
					} else if (this.radio == '2') {
						this.$post('programme/approve', g).then(() => {
							this.$message({
								message: '操作成功',
								type: 'success'
							});
							this.getApproveList(this.tabpaneindex)
							this.checkshow = false
						})
					} else {
						this.$post('programme/approve', g).then(() => {
							this.$post('programme/approveApply', {
								proId: this.approveApplydata.proId,
								authorId: this.approveApplydata.authorId,
								roleId: this.approveApplydata.anthorRoleId,
								checkUserId: 15,
								checkRoleId: this.roleId == 2 ? 3 : 1,
								sponsorId: this.userId
							}).then(() => {
								this.$message({
									message: '审核已通过并提交',
									type: 'success'
								});
								this.getApproveList(this.tabpaneindex)
								this.checkshow = false
							})
						})
					}

				}).catch(() => {

				});
			}
		}
	}
</script>

<style scoped lang="less">
	.checkbox {
		>div {
			margin-bottom: 20px;
		}

		.buttons {
			text-align: center;
			margin-top: 100px;

			>:first-child {
				margin-right: 80px;
			}
		}
	}
</style>
